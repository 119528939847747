<template>
  <div>
    <CRow>
      <CCol sm="12">
        <SuppliersTable
          :items="suppliers"
          hover
          striped
          border
          small
          fixed
          caption="Vendedores"
          @refresh="refreshItems"
          @filtered="refreshItems"
          :permissions="permissions"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SuppliersTable from '../../components/suppliers/SuppliersTable.vue'
import ws from '../../services/suppliers';
import account_ws from '../../services/account';
import store from '../../store'

export default {
  name: 'Suppliers',
  components: { SuppliersTable },
  data: function () {
		return {
            suppliers: [],
            permissions: null
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.suppliers = response.data;
    }

    let response2 = await account_ws.getInfo(); 

    if(response2.type == "success"){
      this.permissions = response2.data.user.permissions;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.suppliers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
